import React from "react";
import { Card, Image, Row, Col, Typography, Button } from "antd";

const { Text } = Typography;

export default function ProductCard({
  img,
  handleTag,
  isTagged,
  categorised,
  selectedProducts,
  setSelectedProducts,
  disabled,
  handleUngroup,
}) {
  return (
    <Card
      bordered
      // hoverable
      style={{
        margin: 10,
        border: "1px solid #e6e7e7",
        borderRadius: 8,
      }}
      bodyStyle={{ padding: 8 }}
      cover={
        <Image
          className="product-image"
          src={`${process.env.REACT_APP_API_ENDPOINT}assets/${img?.file_id}?fit=cover&width=283&height=214&quality=100`}
          preview={{
            src: `${process.env.REACT_APP_API_ENDPOINT}assets/${img?.file_id}?fit=cover&width=1000&height=800&quality=100`,
          }}
        />
      }
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Text type="secondary">
            <b> {img?.name}</b>
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            style={{ padding: 0 }}
            onClick={() => handleUngroup(img)}
            type="link"
           // disabled={disabled}
          >
            <strong> Ungroup</strong>
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
