import React, { Component, useEffect } from "react";

import CreatableSelect from "react-select/creatable";
import API_KEY from "../../api";

export default function CreatableSingle(props) {
  const {
    onCategoryChange,
    addCategoryToCustomer,
    categories,
    setCustCategories,
    setLoading,
    isLoading,
    custCategories,
  } = props;

  useEffect(() => {}, [categories]);
  const handleChange = (newValue = {}, actionMeta) => {
    const value = newValue ? newValue.value : "";
    onCategoryChange(value);
  };
  const createOption = (label, value) => ({ label, value: value });

  const handleCreate = (inputValue) => {
    setLoading(true);

    API_KEY.URL.post(API_KEY.path.cat_config, { name: inputValue })
      .then((res) => {
        const catID = res?.data?.data?.id;
        console.log(inputValue, catID);
        addCategoryToCustomer(catID);
        setCustCategories([
          ...custCategories,
          { category_id: { id: catID, name: inputValue } },
        ]);
        // handleChange({ name: inputValue, value: catID });
      })
      .catch((err) => {
        console.log("err:", err);
      });
    // setTimeout(() => {
    //   const { options } = this.state;
    //   const newOption = createOption(inputValue);
    //   console.log(newOption);
    //   console.groupEnd();
    //   this.setState({
    //     isLoading: false,
    //     options: [...options, newOption],
    //     value: newOption,
    //   });
    // }, 1000);
  };
  const handleInputChange = (inputValue, actionMeta) => {
    // console.group("Input Changed");
    // console.log(inputValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
  };

  return (
    <CreatableSelect
    styles={{width:'100%'}}
      isClearable
      isLoading={isLoading}
      onChange={handleChange}
      // onInputChange={handleInputChange}
      options={categories}
      onCreateOption={handleCreate}
    />
  );
}
