import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import CategoryBrowser from "./CategoryBrowser";
import CategoryBrowserActions from "./CategoryBrowserActions";
import { useParams } from "react-router-dom";
import API_KEY from "../../api";

export default function CategoryPanel(props) {
  const {
    customerId,
    categoryID,
    onCategoryChange,
    directories,
    taggedImages,
    imagesToReassign,
    tagImagesToReassign,
  } = props;
  const [categories, setCustCategories] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  // api call for getting customer's cats
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.category, {
      params: {
        "filter[customer_id][_eq]": customerId,
        fields: "*,*.id,*.name",
      },
    })
      .then((res) => {
        // set data to local state
        setCustCategories(res?.data?.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [customerId]);

  const handleTag = (image) => {
    if (imagesToReassign.map((item) => item.id).includes(image.id)) {
      tagImagesToReassign((state) =>
        state.filter((img) => img?.id !== image?.id)
      );
    } else {
      tagImagesToReassign((state) => [...state, image]);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <CategoryBrowserActions
            categoryID={categoryID}
            onCategoryChange={onCategoryChange}
            categories={categories}
            imagesToReassign={imagesToReassign}
            setTaggedImages={tagImagesToReassign}
            setCustCategories={setCustCategories}
            // handleReassign={handleReassign}
          />
        </Col>
      </Row>
      <Row>
        <CategoryBrowser
          directories={directories}
          categoryID={categoryID}
          categories={categories}
          selectedImages={imagesToReassign}
          taggedImages={taggedImages}
          handleTag={handleTag}
        />
      </Row>
    </>
  );
}
