import React from "react";
import { Row, Col, Typography, TreeSelect, Input, Button, Divider } from "antd";
import { CheckSquareFilled } from "@ant-design/icons";
const { Title, Text } = Typography;
export default function ImageBrowserActions({
  directories,
  handleDirectoryChange,
  handleTagAll,
  handleSearch,
}) {
  const [treeLine, setTreeLine] = React.useState(true);
  const [showLeafIcon, setShowLeafIcon] = React.useState(true);

  const getchildMap = (items = [], prefix) => {
    return items.map((item) => {
      return {
        title: item.name,
        value: prefix + "/" + item.name,
        children:
          item.children?.length > 0
            ? getchildMap(item.children, prefix + "/" + item.name)
            : item.children,
      };
    });
  };

  const getTreeData = () => {
    const treeObj = directories.map((item) => item.tree);
    return treeObj.map((item) => getchildMap(item, ""));
  };

  const treeData = getTreeData();

  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>Categorize all the images </Title>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={10}>
          <TreeSelect
            style={{ width: "100%" }}
            size="large"
            onChange={handleDirectoryChange}
            treeLine={true}
            placeholder="Select directory"
            treeData={treeData[0]}
          ></TreeSelect>
        </Col>

        <Col span={6}>
          <Button
            type="default"
            size="large"
            // icon={<CheckSquareFilled style={{fontSize:22}} />}
            onClick={() => handleTagAll()}
            style={{ width: "100%" }}
          >
            <Row align="middle" justify="center">
              <Col>
                <CheckSquareFilled style={{ fontSize: 20, padding: 5 }} />
              </Col>
              <Col>
                <Text> Select All</Text>
              </Col>
            </Row>
          </Button>
        </Col>

        <Col span={6}>
          <Input.Search
            placeholder="Search Image"
            style={{ width: "100%" }}
            size="large"
            onBlur={(e) => {
              handleSearch(e?.target?.value);
            }}
            onPressEnter={(e) => {
              handleSearch(e?.target?.value);
            }}
            // onSearch={(e) => handleSearch(e?.target?.value)}
          ></Input.Search>
        </Col>
        <Divider style={{ margin: 10 }} />
      </Row>
    </div>
  );
}
