import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import StickyBarActions from "./StickyBarActions";
import ImageBrowser from "./ImageBrowser";
import ImageBrowserActions from "./ImageBrowserActions";
import { useParams } from "react-router-dom";
import CategoryPanel from "./CategoryPanel";
import API_KEY from "../../api";
export default function Index() {
  const params = useParams();
  const customerId = params?.id;
  const [images, setImages] = useState([]);
  const [directories, setDirectories] = useState([]);
  const [taggedImages, setTaggedImages] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [imagesToReassign, tagImagesToReassign] = useState([]);
  const [total, setTotal] = useState([]);
  const [step, setStep] = useState(500);
  const [pageForImageBrowser, setPageForImageBrowser] = useState(1);
  const [filters, setFilters] = useState({
    searchKey: "",
    directory: "",
  });
  const [filterLoading, setFilterLoading] = useState();
  const [isFetching, setIsFetching] = useState();

  const getFilterObject = () => {
    let filterObj = {};
    const keys = Object.keys(filters);

    for (var i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = filters[key];
      switch (key.toLowerCase()) {
        case "searchkey":
          if (value) filterObj["filter[name][_contains]"] = value;
          break;
        case "directory":
          if (value) filterObj["filter[path][_contains]"] = `${value}\/`;
          break;
        default:
          break;
      }
    }
    return filterObj;
  };

  // api call for getting customer's dirs
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.directories, {
      params: {
        "filter[id][_eq]": customerId,
        fields: "*,s3directories.*",
      },
    })
      .then((res) => {
        setDirectories(res?.data?.data[0].s3directories);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [customerId]);

  // api call for getting customer's all images
  useEffect(() => {
    if (directories.length > 0) {
      setIsFetching(true);
      console.log("getting data");
      let filterObject = getFilterObject();
      filterObject["filter[category][_null]"] = true;
      filterObject["sort"] = "-date_updated";
      filterObject["limit"] = step;
      filterObject["meta"] = "filter_count";
      filterObject["offset"] = step * pageForImageBrowser - step;
      filterObject["filter[directory][_in]"] = directories
        .map((dir) => dir?.id)
        .join(",");
      API_KEY.URL.get(API_KEY.path.images, {
        params: filterObject,
      })
        .then((res) => {
          // set data to local state
          setTotal(res?.data?.meta);
          setImages(res?.data?.data);
          setFilterLoading(false);
          setIsFetching(false);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  }, [
    directories,
    imagesToReassign,
    taggedImages,
    filterLoading,
    pageForImageBrowser,
  ]);

  // // fetch images from server
  // const fetchImages = (value) => {
  //   const params = {
  //     "filter[diretory][_in]": directories
  //       .map((dir) => dir?.s3directories_id?.id)
  //       .join(","),
  //     "filter[path][_like]": value,
  //     "filter[category][_null]": true,
  //     limit: -1,
  //   };
  //   API_KEY.URL.get(API_KEY.path.images, {
  //     params: value ? params : {},
  //   })
  //     .then((res) => {
  //       // set data to local state
  //       setImages(res?.data?.data);
  //     })
  //     .catch((err) => {
  //       console.log("err:", err);
  //     });
  // };

  // api call for getting customer's cats
  // useEffect(() => {
  //   API_KEY.URL.get(API_KEY.path.category, {
  //     params: {
  //       "filter[customer_id][_eq]": customerId,
  //       fields: "*,*.id,*.name",
  //     },
  //   })
  //     .then((res) => {
  //       // set data to local state
  //       setCustCategories(res?.data?.data);
  //     })
  //     .catch((err) => {
  //       console.log("err:", err);
  //     });
  // }, [customerId]);

  // handle tag btn from product card
  const handleTag = (image) => {
    if (taggedImages.map((item) => item.id).includes(image.id)) {
      setTaggedImages((state) => state.filter((img) => img?.id !== image?.id));
    } else {
      setTaggedImages((state) => [...state, image]);
    }
  };

  // handle directory change dropdown
  const handleDirectoryChange = (value) => {
    if (value) {
      setFilters((state) => {
        return { ...state, directory: value };
      });
      setFilterLoading(true);
    }
  };

  // handle tag all btn
  const handleTagAll = () => {
    if (images.length === taggedImages.length) {
      setTaggedImages([]);
    } else {
      setTaggedImages(images);
    }
  };

  // handle search
  const handleSearch = (value) => {
    setFilters((state) => {
      return { ...state, searchKey: value };
    });
    setFilterLoading(true);
    // fetchImages(value);
  };

  const onCategoryChange = (categoryID) => {
    setCategoryID(categoryID);
  };
  return (
    <Row>
      <Col span={12} style={{ padding: "20px" }}>
        <Row>
          <Col span={24}>
            <ImageBrowserActions
              directories={directories}
              handleDirectoryChange={handleDirectoryChange}
              handleTagAll={handleTagAll}
              handleSearch={handleSearch}
              total={total}
              setPageForImageBrowser={setPageForImageBrowser}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ImageBrowser
              total={total}
              images={images}
              handleTag={handleTag}
              step={step}
              taggedImages={taggedImages}
              setPageForImageBrowser={setPageForImageBrowser}
              pageForImageBrowser={pageForImageBrowser}
              isFetching={isFetching}
            />
          </Col>
        </Row>
      </Col>
      <Col span={12} style={{ background: "#f5f5f5", padding: "20px" }}>
        <div>
          <CategoryPanel
            style={{ background: "f5f5f5" }}
            onCategoryChange={onCategoryChange}
            customerId={customerId}
            categoryID={categoryID}
            directories={directories}
            taggedImages={taggedImages}
            imagesToReassign={imagesToReassign}
            tagImagesToReassign={tagImagesToReassign}
          />
        </div>
      </Col>
      <Row>
        <Col span={24}>
          <StickyBarActions
            categoryID={categoryID}
            taggedImages={taggedImages}
            setTaggedImages={setTaggedImages}
          />
        </Col>
      </Row>
    </Row>
  );
}
