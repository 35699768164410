import React from "react";
import { Card, Image, Row, Col, Typography, Button } from "antd";

const { Text } = Typography;

export default function ProductCard({
  img,
  handleTag,
  isTagged,
  categorised,
  disabled,
}) {
  const name = (img) => {
    const split = img?.path?.split("/");
    const length = split?.length;
    return split[length ? length - 1 : 0];
  };
  return (
    <Card
      bordered
      // hoverable
      style={{
        margin: 5,
        border: "1px solid #e6e7e7",
        borderRadius: 8,
      }}
      bodyStyle={{ padding: 10 }}
      cover={
        <Image
          className="product-image"
          src={`${process.env.REACT_APP_API_ENDPOINT}assets/${img?.file_id}?fit=cover&width=283&height=214&quality=100`}
          preview={{
            src: `${process.env.REACT_APP_API_ENDPOINT}assets/${img?.file_id}?fit=cover&width=1000&height=800&quality=100`,
          }}
        />
      }
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Text type="secondary" style={{ fontSize: 10 }}>
            {name(img)}
          </Text>
        </Col>

        <Col>
          <Button
            onClick={() => handleTag(img)}
            type="primary"
            size="small"
            disabled={disabled || false}
            style={{
              background: disabled ? "#eee" : isTagged ? "#0B7764" : "black",
              border: 0,
            }}
          >
            {isTagged ? "Selected" : "Select"}
          </Button>
        </Col>
      </Row>
    </Card>
  );
}
