import React from "react";
import { Row, Col, Typography, Pagination } from "antd";
import ProductCard from "../../components/ProductCard";
const { Text } = Typography;
export default function ImageBrowser({
  images,
  handleTag,
  taggedImages,
  total,
  step,
  setPageForImageBrowser,
  pageForImageBrowser,
  isFetching,
}) {
  return (
    <div>
      {isFetching && (
        <Row style={{ marginTop: "1em" }}>
          <Col>
            <Text style={{ color: "#aaa" }}>Fetching Images</Text>
          </Col>
        </Row>
      )}
      {!isFetching && images && images?.length > 0 && (
        <Row style={{ marginTop: "1em", justifyContent: "space-between" }}>
          <Col>
            <Text>
              Showing {Math.min(images?.length, total.filter_count, step)} of{" "}
              {total.filter_count} items
            </Text>
          </Col>
          <Col>
            <Pagination
              current={pageForImageBrowser}
              simple
              onChange={setPageForImageBrowser}
              total={total.filter_count}
              defaultPageSize={step}
              onBlur={setPageForImageBrowser}
            />
          </Col>
        </Row>
      )}

      <Row
        style={{
          marginTop: "1em",
          height: "70vh",
          overflowY: "scroll",
        }}
      >
        {images &&
          images?.length > 0 &&
          images.map((img) => (
            <Col key={img?.id} span={8}>
              <ProductCard
                img={img}
                handleTag={handleTag}
                isTagged={taggedImages.some((i) => i?.id === img?.id)}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
}
