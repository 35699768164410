import React from "react";
import {  Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard/index";
import CategorizeImages from "./CategoryTagging/index";
import ImageClubbing from "./ImageGrouping/index";
export default function Home() {
  return (
    <>
     
        <Switch>
          <Route path="/categorise-image/:id">
            <CategorizeImages />
          </Route>

          <Route path="/image-grouping/:id">
            <ImageClubbing />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      
    </>
  );
}
