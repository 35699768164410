import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import ProductCard from "../../components/ProductCard";
import API_KEY from "../../api";
const { Text } = Typography;

export default function CategoryBrowser(props) {
  const { directories, categoryID, selectedImages, handleTag, taggedImages } =
    props;
  const [categorisedImages, setCategorisedImages] = useState([]);
  // api call for getting customer's all images

  useEffect(() => {
    if (categoryID) {
      if (categoryID == "null") {
        API_KEY.URL.get(API_KEY.path.images, {
          params: {
            "filter[directory][_in]": directories
              ? directories.map((dir) => dir?.id).join(",")
              : "",
            "filter[category][_null]": true,
            sort: "sort,-date_updated",
            limit: -1,
          },
        })
          .then((res) => {
            // set data to local state
            setCategorisedImages(res?.data?.data);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      } else {
        if (typeof categoryID == "number") {
          API_KEY.URL.get(API_KEY.path.images, {
            params: {
              "filter[directory][_in]": directories
                ? directories.map((dir) => dir?.id).join(",")
                : "",
              "filter[category][_eq]": categoryID,
              sort: "-date_updated",
              limit: -1,
            },
          })
            .then((res) => {
              // set data to local state
              setCategorisedImages(res?.data?.data);
            })
            .catch((err) => {
              console.log("err:", err);
            });
        }
      }
    }
  }, [categoryID, selectedImages, taggedImages]);

  return (
    <>
      <Row style={{ marginTop: "1em", width: "100%" }}>
        <Col>
          <Text>{categorisedImages?.length} items</Text>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "1em",
          height: "70vh",
          overflowY: "scroll",
          width: "100%",
        }}
      >
        {categorisedImages &&
          categorisedImages?.length > 0 &&
          categorisedImages.map((img) => (
            <Col key={img?.id} span={8}>
              <ProductCard
                img={img}
                handleTag={handleTag}
                isTagged={selectedImages.some((i) => i?.id === img?.id)}
                categorised={true}
              />
            </Col>
          ))}
      </Row>
    </>
  );
}
