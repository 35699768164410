import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import API_KEY from "../../api";

export default function StickyBarActions(props) {
  const { taggedImages: images, categoryID, setTaggedImages } = props;

  const [loading, setLoading] = useState([false]);
  const loadingState = (index, value) => {
    const states = [...loading];
    states[index] = value;
    setLoading(states);
  };
  const handleClick = (event) => {
    const payload = images.map((item) => {
      return {
        id: item.id,
        category: categoryID,
      };
    });

    loadingState(0, true);
    payload.map((item, value) => {
      API_KEY.URL.patch(`${API_KEY.path.images}/${item.id}`, {
        category: categoryID,
      })
        .then((res) => {
          // set data to local state

          console.log("Updated categories:", res.data);
          setTaggedImages([]);
        })
        .catch((err) => {
          console.log("err:", err);
        })
        .finally(() => {
          loadingState(0, false);
          // setTaggedImages([]);
        });
    });
  };

  const isButtonDisabled =
    typeof categoryID == "object" || !images.length || categoryID == "null";
  return (
    <div>
      <Row
        style={{
          justifyContent: "center",
          position: "fixed",
          bottom: 0,
          left: 0,
          padding: 15,
          width: "100%",
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.12)",
          zIndex: 100,
          background: "white",
        }}
      >
        <Col>
          <Button
            size="large"
            type="primary"
            style={{ width: "10em" }}
            onClick={handleClick}
            loading={loading[0]}
            disabled={isButtonDisabled}
          >
            Assign Category
          </Button>
        </Col>
      </Row>
    </div>
  );
}
