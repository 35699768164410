import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography } from "antd";
import API_KEY from "../../api";
import { useHistory } from "react-router-dom";
const { Title, Text } = Typography;
export default function Dashboard() {
  const history = useHistory();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.customers)
      .then((res) => {
        setUsers(
          res?.data.data?.map((user) => {
            return {
              value: user?.id,
              label: user?.name,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCardClick = (userId) => {
    if (userId) {
      history.push(`/categorise-image/${userId}`);
    }
  };
  return (
    <div>
      <Row>
        <Col offset={4}>
          <Title level={3}>Select one of the users to proceed</Title>
          <Text type="secondary">Select one of the users to proceed</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={4} span={20}>
          <Row>
            {users?.length > 0 &&
              users.map((user) => (
                <Col
                  key={user?.value}
                  span={6}
                  style={{ marginTop: "1em", marginRight: "1em" }}
                >
                  {console.log("user:", user?.value)}
                  <Card
                    onClick={() => handleCardClick(user?.value)}
                    key={user?.value}
                    hoverable
                    bordered
                    style={{ border: " 1px solid #CDCFCE" }}
                  >
                    <Title level={4}>{user?.label}</Title>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
