import axios from "axios";

// const userData = localStorage.getItem("user");
// const user = userData && JSON.parse(userData);
const API_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    params: {
      // access_token: user?.access_token,
    },
  }),
  path: {
    login: "auth/login",
    logout: "auth/logout",
    users: "users",
    customers: "items/customer",
    images: "items/images",
    product: "items/product",
    directories: "items/customer", // "items/customer_s3directories",
    cat_config: "items/category",
    category: "items/customer_category",
    manufacturer: "items/manufacturer",
    secondary_category: "items/secondary_category",
  },
};

export default API_KEY;
