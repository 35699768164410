import React from "react";
import { Row, Col, Typography } from "antd";
import ProductCard from "../../components/ProductCard";
const { Text } = Typography;
export default function ImageBrowser({
  images,
  handleTag,
  taggedImages,
  selectedCategory = "",
}) {
  return (
    <div>
      <Row style={{ marginTop: "1em" }}>
        <Col>
          <Text>{images?.length} items</Text>
        </Col>
      </Row>

      <Row
        style={{
          marginTop: "1em",
          height: "70vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        {images &&
          images?.length > 0 &&
          images.map((img) => (
            <Col key={img?.id} span={8}>
              <ProductCard
                img={img}
                handleTag={handleTag}
                isTagged={taggedImages.some((i) => i?.id === img?.id)}
                disabled={!selectedCategory}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
}
