import { useState, useEffect } from "react";
import Header from "./components/Header";
import SignIn from "./components/SignIn";
import Home from "./components/Home";
import { BrowserRouter as Router } from "react-router-dom";

export default function App() {
  const [isLogedIn, setIsLogedIn] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setIsLogedIn(true);
    }
  }, []);
  return (
    <>
     <Router>
      <Header setIsLogedIn={setIsLogedIn} isLogedIn={isLogedIn} />
      <div className="content-layout" >
        {isLogedIn ? <Home /> : <SignIn setIsLogedIn={setIsLogedIn} />}
      </div>
      </Router>
    </>
  );
}
