import React from "react";
import { Row, Col, Button } from "antd";
import API_KEY from "../../api";
export default function StickyBarActions({
  taggedImages,
  addToExisting,
  selectedCategory,
  customerId,
  createIndividual,
  handleGroupProducts,
  selectedProducts,
}) {
  return (
    <div>
      <Row
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          padding: 15,
          width: "100%",
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.12)",
          zIndex: 100,
          background: "white",
          justifyContent: "start",
        }}
        justify="space-around"
      >
        <Col style={{ margin: "0 30px" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => createIndividual()}
            disabled={
              selectedCategory && taggedImages.length > 0 ? false : true
            }
          >
            Create individual
          </Button>
        </Col>
        <Col style={{ margin: "0 10px" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => handleGroupProducts()}
            disabled={
              selectedCategory && taggedImages.length > 0 ? false : true
            }
          >
            Group as a product
          </Button>
        </Col>
        {/* <Col>
          <Button
            size="large"
            style={{
              border:
                selectedProducts && selectedCategory && taggedImages.length > 0
                  ? "1px dashed #0B7764"
                  : "1px dashed lightgray",
              color:
                selectedProducts && selectedCategory && taggedImages.length > 0
                  ? "#0B7764"
                  : "lightgray",
              background:
                selectedProducts && selectedCategory && taggedImages.length > 0
                  ? "#e6faf6"
                  : "none",
            }}
            onClick={() => addToExisting()}
            disabled={
              selectedProducts && selectedCategory && taggedImages.length > 0
                ? false
                : true
            }
          >
            Add to existing
          </Button>
        </Col> */}
      </Row>
    </div>
  );
}
