import React from "react";
import { Row, Col, Input, Typography, Button } from "antd";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

export default function ProductBrowserActions({
  categories,
  handleMenuClick,
  categoryID,
  onCategoryChange,
  imagesToReassign,
  handleExport,
  products,
  customerId,
}) {
  const history = useHistory();
  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>Products </Title>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Input.Search
            size="large"
            placeholder="Seach products"
          ></Input.Search>
        </Col>
        <Col>
          {/* style={{ justifyContent: "space-between" }}> */}
          <Row style={{ justifyContent: "space-evenly", minWidth: "270px" }}>
            <Col>
              <Button
                style={{ border: "1px solid #0B7764", width: "100%" }}
                size={"large"}
                onClick={() => history.push(`/categorise-image/${customerId}`)}
              >
                Go Back
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                onClick={handleExport}
                disabled={!products.length ? true : false}
              >
                Export as Excel
              </Button>
              {/* <button >
            useJsonToCsv
          </button> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
