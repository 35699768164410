import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Card, Space } from "antd";
import ProductCard from "./ProductCard";
import API_KEY from "../../api";

const { Text, Title } = Typography;

export default function ProductBrowser({
  directories,
  categoryID,
  selectedImages,
  handleTag,
  taggedImages,
  products,
  setSelectedProducts,
  selectedProducts,
  addToExisting,
  handleUngroup,
}) {
  const [categorisedImages, setCategorisedImages] = useState([]);
  // api call for getting customer's all images

  useEffect(() => {
    if (categoryID) {
      if (categoryID == "null") {
        API_KEY.URL.get(API_KEY.path.images, {
          params: {
            "filter[directory][_in]": directories
              ? directories.map((dir) => dir?.s3directories_id?.id).join(",")
              : "",
            "filter[category][_null]": true,
            limit: -1,
          },
        })
          .then((res) => {
            // set data to local state
            setCategorisedImages(res?.data?.data);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      } else {
        if (typeof categoryID == "number") {
          API_KEY.URL.get(API_KEY.path.images, {
            params: {
              "filter[directory][_in]": directories
                ? directories.map((dir) => dir?.s3directories_id?.id).join(",")
                : "",
              "filter[category][_eq]": categoryID,
              limit: -1,
            },
          })
            .then((res) => {
              // set data to local state
              setCategorisedImages(res?.data?.data);
            })
            .catch((err) => {
              console.log("err:", err);
            });
        }
      }
    }
  }, [categoryID, selectedImages, taggedImages]);
  return (
    <>
      {/* <Row style={{ marginTop: "1em" }}>
        <Col>
          <Text>{products?.length} items</Text>
        </Col>
      </Row> */}
      <Row
        style={{
          marginTop: "1em",
          height: "70vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        {products &&
          products?.length > 0 &&
          products.map(
            (img) =>
              img.images &&
              img.images.length > 0 && (
                <Card
                  key={img?.id}
                  style={{
                    width: "100%",
                    marginBottom: "1em",
                    borderRadius: 8,
                  }}
                >
                  <Col key={img?.id} span={24}>
                    <Row align="middle">
                      <Space>
                        <Col>
                          <Title style={{ color: "#aaa" }} level={4}>
                            ID - {img?.id}
                          </Title>
                        </Col>
                        <Col
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "0",
                          }}
                        >
                          <Button
                            type="dashed"
                            style={{
                              color:
                                taggedImages?.length > 0 ? "#0B7764" : "#aaa",
                              border:
                                taggedImages?.length > 0
                                  ? "1px dashed #0B7764"
                                  : "1px dashed lightgray",
                              background:
                                taggedImages?.length > 0
                                  ? "rgb(11, 119, 100, 0.1)"
                                  : "#f5f5f5",
                            }}
                            //disabled={taggedImages?.length > 0 ? false : true}
                            onClick={() => {
                              setSelectedProducts(img);
                              addToExisting(img);
                            }}
                          >
                            Add to this group
                          </Button>
                        </Col>
                      </Space>
                    </Row>
                    <Row>
                      {img.images.map((i, index) => (
                        <Col key={index} span={8}>
                          <ProductCard
                            img={i}
                            handleUngroup={handleUngroup}
                            disabled={img.images.length == 1}
                            // disabled={!taggedImages?.length}
                            // handleTag={handleTag}
                            //isTagged={selectedImages.some((i) => i?.id === img?.id)}
                            // setSelectedProducts={setSelectedProducts}
                            // selectedProducts={selectedProducts}
                            //categorised={true}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Card>
              )
          )}
      </Row>
    </>
  );
}
