import React, { useEffect, useState } from "react";
import { Row, Col, Button, Dropdown, Menu, Divider, Typography } from "antd";
import CreatableSingle from "../../components/common/Creatable";
import { DownOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import API_KEY from "../../api";
const { Title } = Typography;
export default function CategoryBrowserActions(props) {
  const params = useParams();
  const history = useHistory();
  const {
    categories,
    handleMenuClick,
    categoryID,
    onCategoryChange,
    imagesToReassign,
    setTaggedImages,
    setCustCategories,
  } = props;
  let options = [];
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [loading]);

  const addCategoryToCustomer = (categoryID) => {
    const customerId = params?.id;
    API_KEY.URL.post(API_KEY.path.category, {
      customer_id: customerId,
      category_id: categoryID,
    }).then((res) => {
      console.log(`Mapped category ${categoryID}`);
      setLoading(false);
    });
  };

  const reassignImages = (newCategory) => {
    const payload = imagesToReassign.map((item) => {
      return {
        id: item.id,
        category: newCategory.key == "null" ? null : newCategory.key,
        product_id: null,
      };
    });
    payload.map((item) => {
      setLoading(true);
      API_KEY.URL.patch(`${API_KEY.path.images}/${item.id}`, {
        category: item.category,
        product_id: item.product_id,
      })
        .then((res) => {
          // set data to local state
          setLoading(false);
          setTaggedImages([]);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    });
    // setTaggedImages([]);
  };

  options = categories.length
    ? categories.map((item) => {
        return {
          label: item.category_id.name,
          value: item.category_id.id,
        };
      })
    : [];
  // options.push({
  //   label: "Untagged",
  //   value: "null",
  // });
  const menu = (
    <Menu onClick={reassignImages}>
      <Menu.Item key={"null"}>Untagged</Menu.Item>
      {options.map((item) => {
        if (item.value != categoryID) {
          return <Menu.Item key={item.value}>{item.label}</Menu.Item>;
        }
      })}
    </Menu>
  );

  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>Tagged Images </Title>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[40, 0]}>
        <Col span={12}>
          <CreatableSingle
            custCategories={categories}
            // categories={options}
            onCategoryChange={onCategoryChange}
            addCategoryToCustomer={addCategoryToCustomer}
            setCustCategories={setCustCategories}
            setLoading={setLoading}
            isLoading={loading}
            placeholder={"Search or Add a category"}
          >
            {options.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </CreatableSingle>
        </Col>
        <Col span={6}>
          <Dropdown overlay={menu} disabled={!imagesToReassign.length}>
            <Button style={{ width: "100%" }} size={"large"}>
              Reassign <DownOutlined />
            </Button>
          </Dropdown>
        </Col>

        <Col span={6}>
          <Button
            style={{ background: "#0B7764", color: "white", width: "100%" }}
            size={"large"}
            onClick={() => history.push(`/image-grouping/${params?.id}`)}
          >
            Proceed
          </Button>
        </Col>

        <Divider style={{ margin: 10 }} />
      </Row>
    </div>
  );
}

{
  /* <Button size={"large"} onClick={handleReassign}>
Reassign
</Button> */
}
