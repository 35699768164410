import { Row, Col, Button } from "antd";
import Logo from "../assets/logo.png";
import {useHistory} from 'react-router-dom'
export default function Header({ setIsLogedIn, isLogedIn }) {
  const history = useHistory();
  const logout = () => {
    history.push("/")
    setIsLogedIn(false);
    localStorage.removeItem("user");
  };
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ background: "#f6f6f6", zIndex: 10, padding: 12 }}
    >
      <Col>
        <Row align="middle">
          <Col>
            <img
            onClick={()=>history.push("/")}
              src={Logo}
              width="40"
              height="40"
              alt="logo"
              style={{ padding: 5 }}
            />
          </Col>
          <Col>
            <b style={{ fontFamily: "Gilroy", fontSize: 28, padding: 5 }}>
              Onboarding Tool
            </b>
          </Col>
        </Row>
      </Col>
      <Col>
        {isLogedIn && (
          <Button type="ghost" onClick={() => logout()}>
            Logout
          </Button>
        )}
      </Col>
    </Row>
  );
}
