import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import StickyBarActions from "./StickyBarActions";
import ImageBrowser from "./ImageBrowser";
import ImageBrowserActions from "./ImageBrowserActions";
import { useParams } from "react-router-dom";
import ProductBrowserActions from "./ProductBrowserActions";
import ProductBrowser from "./ProductBrowser";
import { useJsonToCsv } from "react-json-csv";
import API_KEY from "../../api";
export default function Index() {
  const params = useParams();
  const customerId = params?.id;
  const [customerName, setCustomerName] = useState([]);
  const [images, setImages] = useState([]);
  const [directories, setDirectories] = useState([]);
  const [custCategories, setCustCategories] = useState([]);
  const [taggedImages, setTaggedImages] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [products, setProducts] = useState([]);
  const { saveAsCsv } = useJsonToCsv();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const productFields =
    "id,images.file_id,images.name,images.id,customer,category";
  // api call for getting customer's dirs
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.directories, {
      params: {
        "filter[id][_eq]": customerId,
        fields: "*,s3directories.*",
      },
    })
      .then((res) => {
        setDirectories(res?.data?.data[0].s3directories);
      })

      .catch((err) => {
        console.log("err:", err);
      });
  }, [customerId]);

  useEffect(() => {
    if (directories.length > 0) {
      API_KEY.URL.get(API_KEY.path.images, {
        params: {
          "filter[directory][_in]": directories.map((dir) => dir?.id).join(","),
          limit: -1,
          "filter[product_id][_null]": true,
          "filter[category][_nnull]": true,
        },
      })
        .then((res) => {
          setImages(res?.data?.data);
          // setCustomerName(res?.data?.data?.name);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  }, [customerId, directories]);

  useEffect(() => {
    if (directories.length > 0) {
      API_KEY.URL.get(`${API_KEY.path.customers}/${customerId}`, {
        params: {
          // "filter[directory][_in]": directories
          //   .map((dir) => dir?.s3directories_id?.id)
          //   .join(","),
          // limit: -1,
          // "filter[product_id][_null]": true,
          // "filter[category][_nnull]": true,
          fields: "name",
        },
      })
        .then((res) => {
          setCustomerName(res?.data?.data?.name);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  });

  // fetch products for initial load
  useEffect(() => {
    // const productFields =
    //   "id,images.images_id.file_id,images.images_id.name,customer,category";
    API_KEY.URL.get(API_KEY.path.product, {
      params: {
        "filter[customer][_eq]": customerId,
        fields: productFields,
        limit: -1,
        "sort[]": "-date_created",
      },
    })
      .then((res) => {
        // set data to local state
        setProducts(res?.data?.data);
        // console.log("products:", res?.data?.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [customerId]);

  // fetch images from server
  const fetchImages = (path, category) => {
    setLoading(true);
    const params = {
      "filter[path][_contains]": path,
      "filter[category][_eq]": category,
      limit: -1,
      "filter[product_id][_null]": true,
    };
    API_KEY.URL.get(API_KEY.path.images, {
      params: path || category ? params : {},
    })
      .then((res) => {
        // set data to local state
        setImages(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  // fetch products from server
  const fetchProducts = (category) => {
    setLoading(true);
    const params = {
      "filter[customer][_eq]": customerId,
      "filter[category][_eq]": category,
      "sort[]": "-date_created",
      fields: productFields,
      limit: -1,
    };
    API_KEY.URL.get(API_KEY.path.product, {
      params: category || customerId ? params : {},
    })
      .then((res) => {
        // set data to local state
        setProducts(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };
  // handle export as excel btn
  const handleExport = () => {
    setLoading(true);
    const params = {
      // "filter[customer][_eq]": customerId,
      "filter[directory][_in]": directories.map((dir) => dir?.id).join(","),
      limit: "-1",
      fields: "*,category.name,category.id",
      // fields:
      //   "id,images.id,images.path,images.file_id,images.name,category.id,category.name",
      // limit: -1,
    };
    API_KEY.URL.get(API_KEY.path.images, {
      params: params,
    })
      .then((res) => {
        const images = res?.data?.data;
        const products = images.reduce((result, item) => {
          result[item.product_id] = [...(result[item.product_id] || []), item];
          return result;
        }, {});

        const productKeys = Object.keys(products);

        const productObjects = productKeys.map((key) => {
          return products[key].map((image) => {
            return {
              id: !key || key == "null" ? "" : key,
              name: image.name,
              url: `https://start.sourcerer.tech/assets/${image.file_id}`,
              path: image.path,
              category: image.category?.name || "",
            };
          });
        });

        // const productObjects = products.map((item) => {
        //   debugger;
        //   return item.images.map((image) => {
        //     return {
        //       id: item.id,
        //       name: image.name,
        //       url: `https://sourcewizpublic.sgp1.digitaloceanspaces.com/${image.path}`,
        //       path: image.path,
        //       category: item.category.name,
        //     };
        //   });
        // });
        const data = [].concat.apply([], productObjects);
        const filename = customerName,
          fields = {
            id: "ID",
            name: "Name",
            url: "Image URL",
            path: "Path",
            category: "Category",
          };

        saveAsCsv({ data, fields, filename });
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  // api call for getting customer's cats
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.category, {
      params: {
        "filter[customer_id][_eq]": customerId,
        fields: "*,*.id,*.name",
      },
    })
      .then((res) => {
        // set data to local state
        setCustCategories(res?.data?.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [customerId]);

  // handle tag btn from product card
  const handleTag = (image) => {
    if (taggedImages.includes(image)) {
      setTaggedImages((state) => state.filter((img) => img?.id !== image?.id));
    } else {
      setTaggedImages((state) => [...state, image]);
    }
  };

  // handle directory change dropdown
  const handleDirectoryChange = (value) => {
    if (value) {
      setSelectedDirectory(value);
      fetchImages(value, selectedCategory);
      fetchProducts(selectedCategory);
    }
  };

  // handle Category change dropdown
  const handleCategoryChange = (value) => {
    if (value) {
      setSelectedCategory(value);
      fetchImages(selectedDirectory, value);
      fetchProducts(value);
      setTaggedImages([]);
    }
  };

  // handle tag all btn
  const handleTagAll = () => {
    if (images.length === taggedImages.length) {
      setTaggedImages([]);
    } else {
      setTaggedImages(images);
    }
  };

  // handle search
  const handleSearch = (value) => {
    fetchImages(value);
  };

  // handle category change
  const onCategoryChange = (categoryID) => {
    setCategoryID(categoryID);
  };

  // create individual product
  const createIndividual = () => {
    setLoading(true);
    const payload = taggedImages.map((item) => {
      return {
        images: [{ id: item?.id }],
        category: selectedCategory,
        customer: !isNaN(customerId) ? parseInt(customerId) : customerId,
      };
    });
    API_KEY.URL.post(API_KEY.path.product, payload)
      .then((res) => {
        fetchImages(selectedDirectory, selectedCategory);
        fetchProducts(selectedCategory);
        setTaggedImages([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // create group(images) products
  const handleGroupProducts = () => {
    setLoading(true);
    const images = taggedImages.map((item) => {
      return {
        id: item?.id,
      };
    });
    const payload = {
      category: selectedCategory,
      customer: !isNaN(customerId) ? parseInt(customerId) : customerId,
      images,
    };
    API_KEY.URL.post(API_KEY.path.product, payload)
      .then((res) => {
        fetchImages(selectedDirectory, selectedCategory);
        fetchProducts(selectedCategory);
        setTaggedImages([]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  // add images to existiing products
  const addToExisting = (prod) => {
    setLoading(true);
    const payload = taggedImages.map((item) => {
      return item?.id;
    });
    const existingImages = prod.images.map((i) => i?.id);
    API_KEY.URL.patch(`${API_KEY.path.product}/${prod?.id}`, {
      images: [...payload, ...existingImages],
    })
      .then((res) => {
        fetchImages(selectedDirectory, selectedCategory);
        fetchProducts(selectedCategory);
        setTaggedImages([]);
        setSelectedProducts(null);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleUngroup = (prod) => {
    setLoading(true);
    API_KEY.URL.patch(`${API_KEY.path.images}/${prod?.id}`, {
      product_id: null,
    })
      .then((res) => {
        // set data to local state
        fetchImages(selectedDirectory, selectedCategory);
        fetchProducts(selectedCategory);
        setTaggedImages([]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={12} style={{ padding: "20px" }}>
          <Row>
            <Col span={24}>
              <ImageBrowserActions
                directories={directories}
                handleDirectoryChange={handleDirectoryChange}
                handleTagAll={handleTagAll}
                handleSearch={handleSearch}
                custCategories={custCategories}
                handleCategoryChange={handleCategoryChange}
                selectedCategory={selectedCategory}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ImageBrowser
                images={images}
                handleTag={handleTag}
                taggedImages={taggedImages}
                selectedCategory={selectedCategory}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ background: "#f6f6f6", padding: "20px" }}>
          <Row>
            <Col span={24}>
              <ProductBrowserActions
                categoryID={categoryID}
                onCategoryChange={onCategoryChange}
                handleExport={handleExport}
                products={products}
                customerId={customerId}
                // categories={categories}
                // imagesToReassign={imagesToReassign}
                // setTaggedImages={tagImagesToReassign}
                // handleReassign={handleReassign}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ProductBrowser
                directories={directories}
                categoryID={categoryID}
                products={products}
                handleUngroup={handleUngroup}
                // categories={categories}
                // selectedImages={imagesToReassign}
                taggedImages={taggedImages}
                handleTag={handleTag}
                setSelectedProducts={setSelectedProducts}
                selectedProducts={selectedProducts}
                addToExisting={addToExisting}
              />
            </Col>
          </Row>
        </Col>
        <Row>
          <Col span={24}>
            <StickyBarActions
              categoryID={categoryID}
              taggedImages={taggedImages}
              customerId={customerId}
              setTaggedImages={setTaggedImages}
              selectedCategory={selectedCategory}
              handleGroupProducts={handleGroupProducts}
              createIndividual={createIndividual}
              addToExisting={addToExisting}
              selectedProducts={selectedProducts}
            />
          </Col>
        </Row>
      </Row>
    </Spin>
  );
}
