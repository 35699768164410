import React, { useState } from 'react'
import { Form, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import {Button} from 'antd'
// import API_KEY from '../api';
import axios from 'axios'
export default function SignIn({ setIsLogedIn }) {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);
    const onFinish = () => {
        const payload = {
            email: email,
            password: password
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}auth/login`, payload).then(res => {
            setIsLogedIn(true);
            localStorage.setItem('user', JSON.stringify(res?.data?.data));
            window.location.reload();
        }).catch(err => {
            setError(err);
            setEmail(null);
            setPassword(null);
        })
    }
    return (

        <>
            {error && <Alert variant="danger" style={{
                textAlign: 'center', margin: "auto",
                width: "50%", zIndex: 100
            }}>Incorrect email or password. Try again!</Alert>}
            <Container fluid style={{
                margin: "auto",
                width: "50%",
                padding: '10px',
                marginTop: '10em'
            }}>
                <Card >
                    <Card.Header>
                        <h3>Sign in</h3>
                    </Card.Header>
                    <Card.Body style={{padding:40}}>
                        <Form >
                            <Row>
                                <Col lg={12} xl={12} md={12} >
                                    <Form.Group className="field"  >
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xl={12} md={12}>
                                    <Form.Group className="field" >
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Button size="large" type="primary" onClick={() => onFinish()} style={{ width: '100%', marginTop: '2em' }}>
                            Sign in
                        </Button>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}
