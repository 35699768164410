import React, { useState, useEffect } from "react";
import { Row, Col, Typography, TreeSelect, Input, Select } from "antd";
import { CheckSquareFilled } from "@ant-design/icons";
const { Title } = Typography;
const { TreeNode } = TreeSelect;
const { Option } = Select;
export default function ImageBrowserActions({
  directories,
  handleDirectoryChange,
  handleTagAll,
  handleSearch,
  custCategories,
  handleCategoryChange,
  selectedCategory,
}) {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    if (custCategories) {
      setCategories(
        custCategories.map((cat) => (
          <Option key={cat?.category_id?.id} value={cat?.category_id?.id}>
            {cat?.category_id?.name}
          </Option>
        ))
      );
    }
  }, [custCategories]);

  const node = (dir) => {
    let child;
    const option = (r) => <TreeNode value={r.name} title={r.name} />;
    const data = dir;
    if (data.tree && data.tree.length > 0) {
      child = data.tree.map((r) => (
        <TreeNode value={data.dir_name} title={data.dir_name}>
          {r.children.length > 0 ? r.children.map((n) => option(n)) : option(r)}
        </TreeNode>
      ));
    } else {
      child = <TreeNode value={data.dir_name} title={data.dir_name} />;
    }
    return child;
  };
  return (
    <div>
      <Row>
        <Col>
          <Title level={4}>Group all the tagged images </Title>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Select
            size="large"
            style={{
              minWidth: 240,
              maxWidth: 240,
              // border: selectedCategory ? "none" : "1px solid #0B7764",
              "box-shadow": selectedCategory ? "none" : "0 0 5px #0B7764",
            }}
            onChange={handleCategoryChange}
            placeholder="Select category to proceed"
          >
            {categories}
          </Select>
        </Col>

        <Col>
          <TreeSelect
            size="large"
            style={{ minWidth: 200 }}
            onChange={handleDirectoryChange}
            treeLine={true}
            placeholder="Select directory"
          >
            {directories.length > 0 &&
              directories?.map((dir, index) => node(dir))}
          </TreeSelect>
        </Col>

        {/* <Col>
          <Button
            type="default"
            size="large"
            icon={<CheckSquareFilled size="large" />}
            onClick={() => handleTagAll()}
          >
            Tag all
          </Button>
        </Col> */}

        <Col>
          <Input.Search
            style={{ maxWidth: 200 }}
            size="large"
            onBlur={(e) => handleSearch(e?.target?.value)}
            onPressEnter={(e) => handleSearch(e?.target?.value)}
            onSearch={(e) => handleSearch(e?.target?.value)}
            placeholder="Search images"
          ></Input.Search>
        </Col>
      </Row>
    </div>
  );
}
